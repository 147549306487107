import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { store, persistor } from "./redux/store";
import routes, { renderRoutes } from "./routes";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { QueryClient, QueryClientProvider } from "react-query";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { SnackbarProvider } from "baseui/snackbar";

Amplify.configure(awsExports);

const queryClient = new QueryClient();
const history = createBrowserHistory();
const engine = new Styletron();

export default function App() {
    return (
        <HelmetProvider>
            <ReduxProvider store={store}>
                <PersistGate loading={<></>} persistor={persistor}>
                    <QueryClientProvider client={queryClient}>
                        <StyletronProvider value={engine}>
                            <BaseProvider theme={LightTheme}>
                                <SnackbarProvider>
                                    <Router history={history}>
                                        {renderRoutes(routes)}
                                    </Router>
                                </SnackbarProvider>
                            </BaseProvider>
                        </StyletronProvider>
                    </QueryClientProvider>
                </PersistGate>
            </ReduxProvider>
        </HelmetProvider>
    );
}
