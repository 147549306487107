import { Redirect } from "react-router-dom";
import useSettings from "../hooks/useSettings";

const GuestGuard: React.FC = ({ children }) => {
    const { isAuthenticated } = useSettings();

    if (isAuthenticated) {
        return <Redirect to={"/admin"} />;
    }

    return <>{children}</>;
};

export default GuestGuard;
