import React, { ReactNode } from "react";
import Logo from "../components/Logo";
import { Button } from "baseui/button";
import useSettings from "../hooks/useSettings";
import { Block } from "baseui/block";
import { Heading, HeadingLevel } from "baseui/heading";

const DashboardLayout = ({ children }: { children?: ReactNode }) => {
    const { logout } = useSettings();

    // const [mainItems, setMainItems] = React.useState([
    //     {
    //         active: true,
    //         icon: ChevronDown,
    //         label: "PCR-Zertifikate",
    //         navExitIcon: Delete,
    //     },
    // ]);

    return (
        <>
            <Block
                maxWidth={"1270px"}
                margin={"3rem auto"}
                // paddingRight="30px"
                // padding={[
                //     "20px 5% 20px 5%",
                //     "20px 5% 20px 5%",
                //     "20px 10% 20px 10%",
                //     "20px 10% 20px 10%",
                // ]}
                display="flex"
                justifyContent="space-between"
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Logo width={50} />

                    <HeadingLevel>
                        <Heading styleLevel={5} marginLeft="1rem">
                            Rochus Apotheke
                        </Heading>
                    </HeadingLevel>
                </div>

                <Button onClick={() => logout()} kind="secondary">
                    Abmelden
                </Button>
            </Block>

            {/* <AppNavBar
                title={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Logo width={50} />
                        <span style={{ marginLeft: "1rem" }}>
                            Rochus Apotheke
                        </span>
                    </div>
                }
                mainItems={mainItems}
                onMainItemSelect={(item) => {}}
                username="Rochus Apotheke"
                // usernameSubtitle="5 Stars"
                userItems={[{ icon: Overflow, label: "Abmelden" }]}
                onUserItemSelect={(item) => {
                    if (item.label === "Abmelden") {
                        logout();
                        return;
                    }
                }}
            /> */}
            <Block
                maxWidth={"1270px"}
                margin={"3rem auto"}
                // paddingRight="30px"
                // padding={[
                //     "20px 5% 20px 5%",
                //     "20px 5% 20px 5%",
                //     "20px 10% 20px 10%",
                //     "20px 10% 20px 10%",
                // ]}
            >
                {children}
            </Block>
        </>
    );
};

export default DashboardLayout;
