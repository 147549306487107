import { Switch, Route } from "react-router-dom";
import { Suspense, Fragment, lazy } from "react";
import { Spinner } from "baseui/spinner";
import GuestGuard from "./guards/GuestGuard";
import AuthGuard from "./guards/AuthGuard";
import SmallLayout from "./layouts/SmallLayout";
import DashboardLayout from "./layouts/DashboardLayout";

export function renderRoutes(routes: RouteItem[] = []) {
    return (
        <Suspense fallback={<Spinner />}>
            <Switch>
                {routes.map((route, idx) => {
                    const Component = route.component;
                    const Guard = route.guard || Fragment;
                    const Layout = route.layout || Fragment;

                    return (
                        <Route
                            key={`routes-${idx}`}
                            path={route.path}
                            exact={route.exact}
                            render={(props: any) => (
                                <Guard>
                                    <Layout>
                                        {route.routes && route.routes ? (
                                            renderRoutes(route.routes)
                                        ) : (
                                            <Component {...props} />
                                        )}
                                    </Layout>
                                </Guard>
                            )}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    );
}

type RouteItem = {
    exact?: boolean;
    guard?: React.FC;
    path?: string | string[];
    component?: any;
    layout?: React.FC;
    routes?: {
        component: any;
        path?: string | string[];
        exact?: boolean;
    }[];
};

const routes: RouteItem[] = [
    {
        exact: true,
        path: "/",
        layout: SmallLayout,
        component: lazy(() => import("./pages/Home")),
        guard: GuestGuard,
    },
    {
        exact: true,
        path: "/verify/:code?",
        layout: SmallLayout,
        component: lazy(() => import("./pages/user/Verify")),
    },
    {
        exact: true,
        path: "/admin/login",
        layout: SmallLayout,
        component: lazy(() => import("./pages/admin/Login")),
        guard: GuestGuard,
    },
    {
        exact: true,
        path: "/admin",
        layout: DashboardLayout,
        component: lazy(() => import("./pages/admin/AdminHome")),
        guard: AuthGuard,
    },
    {
        exact: true,
        path: "*",
        layout: SmallLayout,
        component: lazy(() => import("./pages/Page404")),
    },
];

export default routes;
