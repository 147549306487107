import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import { login, logout } from "../redux/slices/settings";

function useSettings() {
    const dispatch = useDispatch();

    const isAuthenticated = useSelector(
        (state: RootState) => !!state.settings.password
    );

    const password = useSelector((state: RootState) => state.settings.password);
    const hasLoginError = useSelector(
        (state: RootState) => state.settings.hasLoginError
    );

    return {
        isAuthenticated,
        hasLoginError,
        login: (password: string) => dispatch(login(password)),
        logout: () => dispatch(logout()),
        password,
    };

    // const handleToggleTheme = useCallback(
    //     () => dispatch(switchMode(isLight ? "dark" : "light")),
    //     [dispatch, isLight]
    // );

    // const handleChangeTheme = useCallback(
    //     (event) => dispatch(switchMode(event.target.value)),
    //     [dispatch]
    // );

    // const handleChangeDirection = useCallback(
    //     (event) => dispatch(switchDirection(event.target.value)),
    //     [dispatch]
    // );

    // return {
    //     toggleMode: handleToggleTheme,
    //     selectMode: handleChangeTheme,
    //     // Direction
    //     themeDirection,
    //     selectDirection: handleChangeDirection,
    // };
}

export default useSettings;
