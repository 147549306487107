import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "aws-amplify";

export const login = createAsyncThunk(
    "settings/login",
    async (password: string) => {
        await API.post("TestResultsApi", `/login`, {
            headers: { Authorization: `Bearer: ${password}` },
        });
    }
);

type SettingsState = {
    password?: string; // Indicates if the user is logged in
    hasLoginError: boolean;
};

const initialState: SettingsState = { hasLoginError: false };

const slice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        logout: (state) => {
            state.password = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state, action) => {
            state.hasLoginError = false;
        });

        builder.addCase(login.fulfilled, (state, action) => {
            state.password = action.meta.arg;
        });
        builder.addCase(login.rejected, (state, action) => {
            state.hasLoginError = true;
        });
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { logout } = slice.actions;
