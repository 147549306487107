import React, { ReactNode } from "react";
import { Card, StyledBody } from "baseui/card";
import Logo from "../components/Logo";

const SmallLayout = ({ children }: { children?: ReactNode }) => (
    <Card
        overrides={{
            Root: {
                style: {
                    margin: "100px auto",
                    maxWidth: "420px",
                    width: "95vw",
                },
            },
        }}
    >
        <StyledBody>
            <div style={{ textAlign: "center", marginBottom: "2rem" }}>
                <Logo />
            </div>

            {children}
        </StyledBody>
    </Card>
);

export default SmallLayout;
