import React from "react";
import { Redirect } from "react-router-dom";
import useSettings from "../hooks/useSettings";

const AuthGuard: React.FC = ({ children }) => {
    const { isAuthenticated } = useSettings();

    if (!isAuthenticated) {
        return <Redirect to={"/admin/login"} />;
    }

    return <>{children}</>;
};

export default AuthGuard;
